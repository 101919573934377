// ----step - 1 ------
import React, { useEffect, useState } from "react";
import { SearchIcon } from "../../../../utils/MyIcons";
import InfluencerListDetails from "./InfluencerListDetails";
import useFetchData from "../../../../hooks/data-hooks/common/useFetchData";
import Pagination from "../../../common/components/Pagination";
import _ from "lodash"; // Import lodash for debounce
import Loader from "../../../common/components/loader/Loader";
import InfluencerContainer from "../../../web/components/influencer/InfluencerContainer";
import Select from 'react-select'; // Add this import


const InfluencerList = () => {
  const [currentPage, setCurrentPage] = useState(1); // Initial page
  // const influencersPerPage = 4; // Number of influencers to display per page
  const perPage = 999999999999999;
  const [isFirstLoad, setIsFirstLoad] = useState(true); // For tracking the first load
  const [filters, setFilters] = useState({
    gender: "",
    categoryId: "",
    tagId: "",
    search: "",
  });

  // Fetch Influencer data
  const {
    data: mainData,
    isLoading: isFetchingData,
    error,
  } = useFetchData(
    "/api/public/influencer-search",
    "influencerKeylst",
    currentPage,
    perPage,
    filters
  );

  // Fetch categories and tags for filters
  const { data: categoriesData } = useFetchData(
    `/api/v1/public/influencer-category/all`,
    "categoriesKey"
  );
  const { data: tagsData } = useFetchData(`/api/v1/public/tag/all`, "tagsKey");

  // Debounced search function to avoid triggering on every keystroke
  const debouncedSearch = _.debounce((value) => {
    setFilters((prevFilters) => ({ ...prevFilters, search: value }));
    setCurrentPage(1); // Reset to first page when a new search is performed
  }, 500); // Wait 500ms after typing stops

  // Handle filter changes for dropdowns
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    setCurrentPage(1); // Reset to first page after applying filters
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const { value } = e.target;
    debouncedSearch(value); // Trigger debounced search
  };

  // Handle pagination
  const handlePageClick = ({ selected }) => {
    let page = selected + 1;
    if (page > 0) {
      setCurrentPage(page);
    }
  };

  // Track first load
  useEffect(() => {
    if (!isFetchingData) {
      setIsFirstLoad(false); // First load is complete after the data is fetched for the first time
    }
  }, [isFetchingData]);

  // Prepare options for Select components
  const categoriesOptions = [
    { value: "", label: "All Categories" },
    ...(categoriesData?.data?.map((category) => ({
      value: category._id,
      label: category.categoryName,
    })) || []),
  ];

  const tagsOptions = [
    { value: "", label: "All Tags" },
    ...(tagsData?.data?.map((tag) => ({
      value: tag._id,
      label: tag.tagName,
    })) || []),
  ];

  // Add new change handlers for Select components
  const handleCategoryChange = (selectedOption) => {
    setFilters((prev) => ({
      ...prev,
      categoryId: selectedOption?.value || "",
    }));
    setCurrentPage(1);
  };

  const handleTagChange = (selectedOption) => {
    setFilters((prev) => ({
      ...prev,
      tagId: selectedOption?.value || "",
    }));
    setCurrentPage(1);
  };

  // Handle error state
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="container">
      {/* Influencers List */}
      <div className="shadow">
        <div className="p-4 bg-white rounded-3  min-h-600px">
          {/* Filters Section */}
          <section className="py-3">
            <div className="container my-4">
              <div className="row row-gap-3">
                {/* Gender */}
                <div className="col-12 col-sm-6 col-lg-3">
                  <select
                    name="gender"
                    value={filters.gender}
                    onChange={handleFilterChange}
                    className="form-control"
                  >
                    <option value="">All Genders</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                {/* Category Dropdown with Search */}
                <div className="col-12 col-sm-6 col-lg-3">
                  <Select
                    name="categoryId"
                    value={categoriesOptions.find(
                      (opt) => opt.value === filters.categoryId
                    )}
                    onChange={handleCategoryChange}
                    options={categoriesOptions}
                    isSearchable
                    placeholder="Select Category"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>

                {/* Tag Dropdown with Search */}
                <div className="col-12 col-sm-6 col-lg-3">
                  <Select
                    name="tagId"
                    value={tagsOptions.find(
                      (opt) => opt.value === filters.tagId
                    )}
                    onChange={handleTagChange}
                    options={tagsOptions}
                    isSearchable
                    placeholder="Select Tag"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>
                {/* Search */}
                <div className="col-12 col-sm-6 col-lg-3">
                  <div className="search border border-1 rounded-3 d-flex align-items-center">
                    <span className="ps-3">
                      <SearchIcon />
                    </span>
                    <input
                      type="text"
                      className="rounded-2 p-2 w-min-250px border-0 bg-transparent"
                      placeholder="Search by name"
                      aria-label="Search"
                      name="searchTerm"
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Only show full-page loader on first load */}
          {isFirstLoad && <Loader />}

          {!isFirstLoad && (
            <>
              <InfluencerContainer
                data={mainData?.data}
                isLoading={isFetchingData}
                advertiser
              />
              <Pagination
                pageCount={Math.ceil(
                  mainData?.meta?.pagination?.total_page ?? 1
                )}
                currentPage={currentPage}
                handlePageClick={handlePageClick}
                isLoading={isFetchingData}
                error={error}
              />
            </>
          )}
          {/* <div className="mt-5"></div> */}
        </div>
      </div>
    </div>
  );
};

export default InfluencerList;
