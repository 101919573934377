import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "../page-header/PageHeader";
import InfluencerContainer from "../influencer/InfluencerContainer";
import useFetchData from "../../../../hooks/data-hooks/common/useFetchData";
import Loader from "../../../common/components/loader/Loader";
import Pagination from "../../../common/components/Pagination";
import _ from "lodash"; // Import lodash for debounce
import { SearchIcon } from "../../../../utils/MyIcons";
import Select from 'react-select'; // Add this import


const AllInfluencers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 9999999999999;
  const [isFirstLoad, setIsFirstLoad] = useState(true); // For tracking the first load
  const [filters, setFilters] = useState({
    gender: "",
    categoryId: "",
    tagId: "",
    search: "",
  });

  // Fetch Influencer data
  const {
    data: mainData,
    isLoading: isFetchingData,
    error,
  } = useFetchData(
    "/api/public/influencer-search",
    "influencerKeyWeb",
    currentPage,
    perPage,
    filters
  );

  // Fetch categories and tags for filters
  const { data: categoriesData } = useFetchData(
    `/api/v1/public/influencer-category/all`,
    "categoriesKey"
  );
  const { data: tagsData } = useFetchData(`/api/v1/public/tag/all`, "tagsKey");

  // Debounced search function to avoid triggering on every keystroke
  const debouncedSearch = _.debounce((value) => {
    setFilters((prevFilters) => ({ ...prevFilters, search: value }));
    setCurrentPage(1); // Reset to first page when a new search is performed
  }, 500); // Wait 500ms after typing stops

  // Handle filter changes for dropdowns
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    setCurrentPage(1); // Reset to first page after applying filters
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const { value } = e.target;
    debouncedSearch(value); // Trigger debounced search
  };

  // Handle pagination
  const handlePageClick = ({ selected }) => {
    let page = selected + 1;
    if (page > 0) {
      setCurrentPage(page);
    }
  };

  // Track first load
  useEffect(() => {
    if (!isFetchingData) {
      setIsFirstLoad(false); // First load is complete after the data is fetched for the first time
    }
  }, [isFetchingData]);

  // Handle error state

  // Prepare options for Select components
  const categoriesOptions = [
    { value: "", label: "All Categories" },
    ...(categoriesData?.data?.map((category) => ({
      value: category._id,
      label: category.categoryName,
    })) || []),
  ];

  const tagsOptions = [
    { value: "", label: "All Tags" },
    ...(tagsData?.data?.map((tag) => ({
      value: tag._id,
      label: tag.tagName,
    })) || []),
  ];

  // Add new change handlers for Select components
  const handleCategoryChange = (selectedOption) => {
    setFilters((prev) => ({
      ...prev,
      categoryId: selectedOption?.value || "",
    }));
    setCurrentPage(1);
  };

  const handleTagChange = (selectedOption) => {
    setFilters((prev) => ({
      ...prev,
      tagId: selectedOption?.value || "",
    }));
    setCurrentPage(1);
  };

  return (
    <div className="w-100 bg-white">
      <Helmet>
        <title>All Influencers | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <PageHeader
        header={"All Influencers"}
        subTitle={"Home / All Influencers"}
      />
      <section className="py-5">
        <div className="container my-4">
          <h2 className="mb-4">All Influencers</h2>

          {/* Filters Section */}
          <section className="py-3">
            <div className="container my-4">
              <div className="row row-gap-3">
                {/* Gender */}
                <div className="col-12 col-sm-6 col-lg-3">
                  <select
                    name="gender"
                    value={filters.gender}
                    onChange={handleFilterChange}
                    className="form-control"
                  >
                    <option value="">All Genders</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                {/* Category Dropdown with Search */}
                <div className="col-12 col-sm-6 col-lg-3">
                  <Select
                    name="categoryId"
                    value={categoriesOptions.find(
                      (opt) => opt.value === filters.categoryId
                    )}
                    onChange={handleCategoryChange}
                    options={categoriesOptions}
                    isSearchable
                    placeholder="Select Category"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>

                {/* Tag Dropdown with Search */}
                <div className="col-12 col-sm-6 col-lg-3">
                  <Select
                    name="tagId"
                    value={tagsOptions.find(
                      (opt) => opt.value === filters.tagId
                    )}
                    onChange={handleTagChange}
                    options={tagsOptions}
                    isSearchable
                    placeholder="Select Tag"
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>
                {/* Category */}
                {/* <div className="col-12 col-sm-6 col-lg-3">
                  <select
                    name="categoryId"
                    value={filters.categoryId}
                    onChange={handleFilterChange}
                    className="form-control"
                  >
                    <option value="">All Categories</option>
                    {categoriesData?.data?.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category?.categoryName}
                      </option>
                    ))}
                  </select>
                </div> */}
                {/* Tag */}
                {/* <div className="col-12 col-sm-6 col-lg-3">
                  <select
                    name="tagId"
                    value={filters.tagId}
                    onChange={handleFilterChange}
                    className="form-control"
                  >
                    <option value="">All Tags</option>
                    {tagsData?.data?.map((tag) => (
                      <option key={tag._id} value={tag._id}>
                        {tag?.tagName}
                      </option>
                    ))}
                  </select>
                </div> */}
                {/* Search */}
                <div className="col-12 col-sm-6 col-lg-3">
                  <div className="search border border-1 rounded-3 d-flex align-items-center">
                    <span className="ps-3">
                      <SearchIcon />
                    </span>
                    <input
                      type="text"
                      className="rounded-2 p-2 w-min-250px border-0 bg-transparent"
                      placeholder="Search by name"
                      aria-label="Search"
                      name="searchTerm"
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Only show full-page loader on first load */}
        {isFirstLoad && <Loader />}

        {/* Show influencer container only after first load */}
        {!isFirstLoad && (
          <>
            <InfluencerContainer
              data={mainData?.data}
              isLoading={isFetchingData} // Pass loading state to control section-specific loader
            />

            <Pagination
              pageCount={Math.ceil(mainData?.meta?.pagination?.total_page ?? 1)}
              currentPage={currentPage}
              handlePageClick={handlePageClick}
              isLoading={isFetchingData}
              error={error}
            />
          </>
        )}
      </section>
    </div>
  );
};

export default AllInfluencers;
