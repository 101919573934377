import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  UserIcon,
  LockIcon,
  EyeOpenIcon,
  EyeCloseIcon,
} from "../../../../utils/WebIcons";
import { DotLoader } from "react-spinners";


import { useForm } from "react-hook-form";
import usePasswordToggle from "../../../../hooks/ui-hooks/usePasswordToggle";
import { Helmet } from "react-helmet-async";
import { authPrefixedRoutes } from "../../../../routes/all-routes/authRoutes";
import { toast } from "react-toastify";
import useLogin from "../../../../hooks/data-hooks/auth/useLogin"; // Importing custom hook for API call

function Login() {
  const [getPasswordInputType, getPasswordIcon, togglePasswordVisibility] =
    usePasswordToggle();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  // Using custom hook for API call

  // Using custom hook for API call
  const {
    loginUser,
    isError,
    loginError,
    submitSuccess,
    loginSuccessResponse,
    isLoading,
    isLoadingLogin,
  } = useLogin();

  // console.log(loginError)

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(loginError.message);
    }
  }, [isError, loginError]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      reset();
      toast.success(loginSuccessResponse.message);
    }
  }, [submitSuccess, loginSuccessResponse]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      // Call the custom hook for API call
      loginUser(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="auth-card px-4 px-sm-5 py-5 mt-sm-5">
        <div className="text-center mb-5">
          <div className="title-32 fw-bold mb-2">Log in to your Account</div>
          <div className="fs-18px">
            Enter to continue and explore within your grasp
          </div>
        </div>
        <form className="web-form mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-item position-relative">
            {errors.email && (
              <div className="text-danger">{errors.email.message}</div>
            )}
            <div className="input-wrapper position-relative">
              <span className="position-absolute top-50 icon-start translate-middle-y">
                <UserIcon />
              </span>
              <input
                type="email"
                className={`${errors.email ? "is-invalid" : ""} custom-input`}
                placeholder="Enter email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Invalid email address",
                  },
                })}
              />
            </div>
          </div>
          <div className="form-item position-relative">
            {errors.password && (
              <div className="text-danger">{errors.password.message}</div>
            )}
            <div className="input-wrapper position-relative">
              <input
                type={getPasswordInputType()}
                className={`${
                  errors.password ? "is-invalid" : ""
                } custom-input`}
                placeholder="Enter password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                })}
              />

              <span className="position-absolute top-50 icon-start translate-middle-y">
                <LockIcon />
              </span>
              <span
                onClick={togglePasswordVisibility}
                className="cursor-pointer position-absolute top-50 icon-end translate-middle-y"
              >
                {getPasswordIcon() === "eye-open" ? (
                  <EyeOpenIcon />
                ) : (
                  <EyeCloseIcon />
                )}
              </span>
            </div>
          </div>
          <div className="text-end mb-3 ">
            <NavLink
              to={authPrefixedRoutes.FORGET_PASSWORD}
              className="text-decoration-none font-color-text"
            >
              Forgot Password ?
            </NavLink>
          </div>

          <button
            type="submit"
            className="w-100 button-primary h-64px"
            disabled={isLoadingLogin}
          >
            {isLoadingLogin ? (
              <div className="d-flex align-items-center justify-content-center gap-4">
                <DotLoader size={20} color="#fff"/>
                <span>Logging in...</span>
              </div>
            ) : (
              "Login"
            )}
          </button>
        </form>
        <div className="text-center">
          <div>
            Don't have an account ?{" "}
            <NavLink
              to={authPrefixedRoutes.CLINIC_SIGNUP}
              className="text-decoration-none font-color-primary font-bold"
            >
              Sign up as Advertiser
            </NavLink>
          </div>
          <div>or,</div>
          <div>
            You can{" "}
            <NavLink
              to={authPrefixedRoutes.LAB_SIGNUP}
              className="text-decoration-none font-color-primary font-bold"
            >
              Sign up as Influencer
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
